<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body" v-if="data != null">
        <posting-category-d-istribution-pillar-graph
          v-if="item.settings.visualViewMode == 'Pillar'"
          :data="data"
        />
        <b-table
          v-else
          small
          responsive
          :fields="tableFields"
          :items="tableItems"
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          class="mt-2"
        >
          <template #cell(postingCategory)="data">
            {{ T(`Web.Generic.PostingCategories.${data.value}`) }}
          </template>
          <template #cell(quantity)="data">
            {{ data.value | toCurrency }} {{T('Web.Generic.Units.Pcs')}}
          </template>
        </b-table>
      </div>
    </div>
  </base-widget>
</template>

<script>
import ContentModal from "@/app/common/modals/ContentModal.vue";
import BaseWidget from "../common/BaseWidget.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import {
  BButton,
  BDropdownItem,
  BCardTitle,
  BCardSubTitle,
  BTable,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import ResourceService from "@/services/base/resource.service";
import PostingCategoryDIstributionPillarGraph from "./PostingCategoryDIstributionPillarGraph.vue";
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    BaseWidget,
    ContentModal,
    SettingsModalInner,
    BButton,
    BDropdownItem,
    BCardTitle,
    BCardSubTitle,
    BTable,
    PostingCategoryDIstributionPillarGraph,
  },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      data: null,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
    };
  },
  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernPostingsDistributionWidget.Title",
        "Posting overview"
      );
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    tableFields() {
      return [
        {
          key: "postingCategory",
          label: this.T("Web.DashboardPage.WidgetStore.Widgets.ConcernPostingsDistributionWidget.Table.PostingCategory", "Posting category"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.T("Web.DashboardPage.WidgetStore.Widgets.ConcernPostingsDistributionWidget.Table.PostedAmount", "Posted amount"),
          sortable: false,
        },
      ];
    },
    tableItems() {
      return this.data != null ? this.data : [];
    },
  },
  methods: {
    async fetchData() {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
      };
      const service = new ResourceService(
        `concern/statistics/postings/categories/distribution`
      );
      var result = (await service.get("", requestParams)).data;
      this.data = result.data;
    },

    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>