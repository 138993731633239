<template>
  <v-chart class="chart" :option="chartOptions" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  props: {
    data: {
      required: true,
    },
  },
  computed: {
    chartOptions() {
      const self = this;
      let options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            console.log(params);

            return `${self.$options.filters.toCurrency(params.data.value)} ${
              params.data.unit
            } (${self.$options.filters.toCurrency(params.percent)}%)`;
          },
        },
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: ["25%", "49%"],
            label: {
              position: "inner",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#234328",
            },

            data: [
              {
                value: this.data.reuseCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.recycleCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.combostionCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.specialCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.landfillCO2,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
            ],
          },
          {
            type: "pie",
            selectedMode: "single",
            radius: ["50%", "74%"],
            label: {
              position: "inner",
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#305e38",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.data.reuseTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.recycleTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.combostionTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.specialTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
              {
                value: this.data.landfillTons,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.T('Web.Generic.Units.Tons'),
              },
            ],
          },
          {
            type: "pie",
            radius: ["75%", "100%"],
            label: {
              position: "inner",
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
              color: "#438651",
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.data.reuseExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/reusesvg.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.currencyScope,
              },
              {
                value: this.data.rezyclExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/rezycl.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.currencyScope,
              },
              {
                value: this.data.combostionExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/CombostionCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit:this.currencyScope,
              },
              {
                value: this.data.specialExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/SpecialCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.currencyScope,
              },
              {
                value: this.data.landfillExpenses,
                label: {
                  backgroundColor: {
                    image: require("@/assets/images/widget/LandfillCategory.png"),
                  },
                  width: 25,
                  height: 25,
                },
                unit: this.currencyScope,
              },
              ,
            ],
          },
        ],
      };
      return options;
    },
  },
};
</script>