<template>
  <div>
    <b-overlay :show="loading">
      <b-tabs pills card vertical>
        <b-tab
          v-for="(category, index) in widgetCategories"
          :key="index"
          :title="category.title"
          lazy
        >
          <div class="widget-list">
            <div
              class="widget-item"
              v-for="(widget, index) in category.widgets"
              :key="index"
              v-show="
                widget.settings.concernLock == undefined ||
                widget.settings.concernLock.includes(currentAccountId)
              "
            >
              <h1>
                {{ widget.title }}
              </h1>
              <p>{{ widget.description }}</p>
              <div class="preview-con d-flex justify-content-center mb-1">
                <img
                  v-for="(preview, index) in widget.previews"
                  :src="preview.path"
                  :key="index"
                  :height="preview.height"
                  :width="preview.width"
                />
              </div>
              <icon-button icon="PlusIcon" @click="addWidgetClicked(widget)">{{
                T(
                  "Web.DashboardPage.WidgetStore.AddToDashboard",
                  "Add to dashboard"
                )
              }}</icon-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
import { BTabs, BTab, BOverlay } from "bootstrap-vue";
import AddButton from "@/app/components/layout/buttons/AddButton.vue";
import IconButton from "@/app/components/layout/buttons/IconButton.vue";
import authHelper from "@/auth";

export default {
  components: {
    BTabs,
    BTab,
    AddButton,
    BOverlay,
    IconButton,
  },
  props: ["loading"],
  methods: {
    addWidgetClicked(widget) {
      this.$emit("widget-add-clicked", widget);
    },
  },

  computed: {
    currentAccountId() {
      return authHelper.getAccountId();
    },
    widgetCategories() {
      return [
        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Financials",
            "Financials"
          ),
          widgets: [
            {
              type: "ConcernSummationWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetIncome.Title",
                "Summed income"
              ),
              previews: [
                {
                  path: require("@/assets/images/widgets/previews/incomeSummationWidget.png"),
                  width: 200,
                  height: 200,
                },
              ],
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetIncome.Description",
                "Display gross or net total summed income in a given period. There is an option to filter the results at the location and fraction levels."
              ),
              settings: {
                dataSource: "IncomeBrutto",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernSummationWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetExpenses.Title",
                "Summed expenses"
              ),
              previews: [
                {
                  path: require("@/assets/images/widgets/previews/chargeSummationWidget.png"),
                  width: 200,
                  height: 200,
                },
              ],
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetExpenses.Description",
                "Display gross or net total summed expenses in a given period. There is an option to filter the results at the location and fraction levels."
              ),
              settings: {
                dataSource: "ChargeBrutto",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernFinancialOverviewWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.Title",
                "Financial overview"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.Description",
                "Displays a financial overview for a given period and compares it to the same period last year. There is an option to filter the results at the location and fraction levels. Display options: column chart and table."
              ),
              settings: {
                visualViewMode: "Pillar",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 4,
                    height: 2,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 4,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernWasteCategoriesDistributionWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteCategoriesDistributionWidget.Title",
                "Distribution overview by category"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteCategoriesDistributionWidget.Description",
                "Displays an overview of the distribution of finances, collected tons, and CO2 across categories. Display options: pyramid and pie chart."
              ),

              settings: {
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                viewMode: "Pyramid",
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 4,
                    height: 3,
                  },
                },
              },
            },
            {
              type: "ConcernInvoicedFractionPricesWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.Title",
                "Selling prices"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.Description",
                "Displays the latest billed charges/expenses (gross or net) for a fraction, distributed by locations. Display options: column or line chart."
              ),
              settings: {
                dataSource: "IncomeBrutto",
                timespanViewMode: "Monthly",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                dimmensions: {
                  default: {
                    width: 4,
                    height: 2,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernFractionsOverviewWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionsOverviewWidget.Title",
                "Economy by fraction."
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionsOverviewWidget.Description",
                "Displays revenues or expenses (gross or net) per fraction per location. Display option: column chart."
              ),
              settings: {
                dataSource: "IncomeBrutto",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernUnitPricesWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernUnitPricesWidget.Title",
                "Unit prices"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernUnitPricesWidget.Description",
                "."
              ),
              settings: {
                infocardId: null,
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
          ],
        },
        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Collections",
            "Collections"
          ),
          widgets: [
            {
              type: "ConcernPercentageDistributionWidget",
              title: "Procent fordeling",
              description: "",
              settings: {
                dataSource: "Infocards",
                locationsFilter: [],
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernFractionsOverviewWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionsOverviewWidgetCollections.Title",
                "Fraction overview"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.oncernFractionsOverviewWidgetCollections.Description",
                "Displays the number of tons collected per fraction per location. Display options: column chart"
              ),
              settings: {
                dataSource: "TonsWasteCollected",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernSummationWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollections.Title",
                "Total amount of tons collected"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollections.Description",
                "Display the total aggregated tons collected in a given period. There is an option to filter the results at the location and fraction levels."
              ),
              previews: [],
              settings: {
                dataSource: "TonsWasteCollected",
                periodeScope: {
                  periode: null,
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernSummationWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollectionsSum.Title",
                "Total number of pick-ups or collections"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollectionsSum.Description",
                "Display the total aggregated number of pick-ups or collections in a given period. There is an option to filter the results at the location and fraction levels."
              ),
              previews: [],
              settings: {
                dataSource: "WasteCollections",
                periodeScope: {
                  periode: null,
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernWasteCategoriesDistributionWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteCategoriesDistributionWidgetCollections.Title",
                "Distribution overview by category"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteCategoriesDistributionWidgetCollections.Description",
                "Displays an overview of the distribution of collected tons across categories. Display options: pyramid and pie chart."
              ),
              settings: {
                periodeScope: {
                  periode: null,
                  customFromDate: null,
                  customToDate: null,
                },
                viewMode: "Pyramid",
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 4,
                    height: 3,
                  },
                },
              },
            },
            {
              type: "ConcernSummationWidget",
              previews: [],
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollectionsKm.Title",
                "Total kilometers driven"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCollectionsKm.Description",
                "Display the total number of kilometers driven in a given period. There is an option to filter the results at the location and fraction levels."
              ),
              settings: {
                dataSource: "KmDriven",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernFractionSetsWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Title",
                "Fraction sets"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Description",
                "."
              ),
              settings: {
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 4,
                    height: 3,
                  },
                },
              },
            },
            {
              type: "ConcernInfocardCollectionsWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Title",
                "Infocard waste collections"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Description",
                "."
              ),
              settings: {
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 4,
                    height: 3,
                  },
                },
              },
            },
          ],
        },
        {
          title: this.T("Web.DashboardOage.WidgetStore.Categories.CO2", "Co2"),
          widgets: [
            {
              type: "ConcernSummationWidget",
              previews: [],
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCO2.Title",
                "Summed Co2"
              ),
              description: this.T(
                "Web.Dashboard.WidgetStore.StoreItems.ConcernSummationWidgetCO2.Description",
                "Display the total aggregated CO2 emissions for a given period. There is an option to filter the results at the location and fraction levels."
              ),
              settings: {
                dataSource: "CO2Emission",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernSummationWidget",
              previews: [],
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCO2IncTransport.Title",
                "Summed Co2 inc. transport"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernSummationWidgetCO2IncTransport.Description",
                "Display total aggregated CO2 emissions, including transportation, for a given period. There is an option to filter the results at the location and fraction levels."
              ),
              settings: {
                dataSource: "CO2EmissionIncludingTransport",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 2,
                    height: 2,
                  },
                  max: {
                    width: 4,
                    height: 4,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
          ],
        },
        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Postings",
            "Postings"
          ),
          widgets: [
            {
              type: "ConcernPostingsDistributionWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernPostingsDistributionWidget.Title",
                "Posting distribution"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernPostingsDistributionWidget.Description",
                "View distribution pr. posting category. Filter on location and fraction. Display options: table or column chart."
              ),
              settings: {
                visualViewMode: "Pillar",
                periodeScope: {
                  periode: "PastYear",
                  customFromDate: null,
                  customToDate: null,
                },
                dimmensions: {
                  default: {
                    width: 4,
                    height: 2,
                  },
                  max: {
                    width: 10,
                    height: 10,
                  },
                  min: {
                    width: 3,
                    height: 2,
                  },
                },
              },
            },
          ],
        },

        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Orders",
            "Orders"
          ),
          widgets: [
            {
              type: "ConcernOrdersOverviewWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernOrdersOverviewWidget.Title",
                "Ordreoversigt"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernOrdersOverviewWidget.Description",
                "Shows recent orders in a given period"
              ),
              settings: {
                periodeScope: {
                  periode: "PastMonth",
                  customFromDate: null,
                  customToDate: null,
                },

                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 12,
                    height: 12,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
          ],
        },

        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Compliance",
            "Compliance"
          ),
          widgets: [
            {
              type: "ConcernWasteFractionSortingComplianceWidget",
              title: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.Title",
                "Lovpligtige sorterings fraktioner"
              ),
              description: this.T(
                "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.Description",
                "Overblik over lovpligtige fraktioner på tværs af lokationer"
              ),
              settings: {
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 12,
                    height: 12,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
          ],
        },
        {
          title: this.T(
            "Web.DashboardPage.WidgetStore.Categories.Reporting",
            "Reporting"
          ),
          widgets: [
            {
              type: "ConcernKyoceraUnimercoWidget",
              title: "Kyocera",
              description: "",
              settings: {
                concernLock: [
                  "d3609683-b484-4978-851c-396a8f2ce4e5",
                  "0302ed2f-0539-49ed-af2f-2ee648173e58",
                ],
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 12,
                    height: 12,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
            {
              type: "ConcernInvoiceExportWidget",
              title: "Faktura eksportering",
              description: "",
              settings: {
                dimmensions: {
                  default: {
                    width: 6,
                    height: 3,
                  },
                  max: {
                    width: 12,
                    height: 12,
                  },
                  min: {
                    width: 2,
                    height: 2,
                  },
                },
              },
            },
          ],
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-list {
  .widget-item {
    h1 {
      font-weight: 200;
    }
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
</style>


<style>
.nav-pills .nav-link.active {
  border-color: black;
}
.nav-pills .nav-link.active {
  background: black;
  box-shadow: 0 4px 18px -4px rgb(0 0 0 / 65%);
}
</style>