<template>
  <b-container fluid>
    <b-row class="wrap">
      <b-col cols="12">
        <b-form-group :label="T('Web.DashboardPage.WidgetStore.Widgets.LocationsFilter')">
          <b-form-select
            :options="locationSoptions"
            v-model="settings.locationScopeId"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { BContainer, BRow, BCol, BFormGroup, BFormSelect } from "bootstrap-vue";
export default {
  props: {
    settings: {
      required: true,
    },
  },
  created()
  {
    if(this.settings.locationScopeId == undefined) this.settings.locationScopeId = null;
  },
  components: {
    BFormSelect,
    BFormGroup,
    BContainer,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    locationSoptions() {
      let list = this.locations.reduce((prev, curr) => {
        prev.push({ text: curr.name, value: curr.id });
        return prev;
      }, []);
      list.unshift({ text: this.T("Web.Generic.Locations.AllLocations"), value: null });
      return list;
    },
  },
};
</script>