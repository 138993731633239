<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body">
        <template v-if="canShowData">
          <fraction-price-line-chart
            :data="data"
            :timespan-view="item.settings.timespanViewMode"
            :visual-view="item.settings.visualViewMode"
          />
        </template>
        <template v-else>
          <div class="h-100 d-flex align-items-center justify-content-center">
            <h3 class="text-muted text-center">
              {{
                T(
                  "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.ChooseFractionAndLocationToStart",
                  "Choose fraction and min one location in settings"
                )
              }}
            </h3>
          </div>
        </template>
        <!--
        -->
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "../common/BaseWidget.vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BTable,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import FractionPriceLineChart from "./FractionPriceLineChart.vue";
import { mapActions, mapGetters } from "vuex";
import ResourceService from "@/services/base/resource.service";

export default {
  components: {
    BaseWidget,
    ContentModal,
    SettingsModalInner,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BDropdownItem,
    BButton,
    FractionPriceLineChart,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  async created() {
    await this.fetchWasteFractions();
    if (this.canShowData) await this.fetchData();
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
      data: null,
    };
  },
  computed: {
    ...mapGetters({ getWasteFraction: "wasteFractions/byId" }),
    canShowData() {
      return (
        this.item.settings.locationsFilter &&
        this.item.settings.locationsFilter.length > 0 &&
        this.item.settings.wasteFractionsFilter
      );
    },

    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      let title = this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.Title",
        "Selling prices"
      );
      if (this.item.settings.wasteFractionsFilter) {
        const fraction = this.getWasteFraction(
          this.item.settings.wasteFractionsFilter
        );
        if (fraction) title += ` ${fraction.name}`;
      }
      switch (this.dataSource) {
        case "IncomeBrutto":
          title += ` - ${this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeBrutto", "Gross income")}`;
          break;
        case "IncomeNetto":
          title += ` - ${this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeNetto", "Net income")}`;
          break;
        case "ChargeBrutto":
          title += ` - ${this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeBrutto", "Gross charge")}`;
          break;
        case "ChargeNetto":
          title += ` - ${this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeNetto", "Net charge")}`;
          break;
      }
      return title;
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    dataSource() {
      return this.item.settings.dataSource;
    },
  },
  methods: {
    ...mapActions({ fetchWasteFractions: "wasteFractions/fetchList" }),
    async fetchData() {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
      };
      const service = new ResourceService(
        `concern/statistics/financials/fractions`
      );
      let uri = "";
      switch (this.dataSource) {
        case "IncomeBrutto":
        case "IncomeNetto":
          requestParams = {
            ...requestParams,
            showBruttoResult: this.dataSource == "IncomeBrutto",
          };
          uri = "invoiced-income";
          break;
        case "ChargeBrutto":
        case "ChargeNetto":
          requestParams = {
            ...requestParams,
            showBruttoResult: this.dataSource == "ChargeBrutto",
          };
          uri = "invoiced-expenses";
          break;
      }
      let response = await service.get(uri, requestParams);
      this.data = response.data.data;
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      if (this.canShowData) await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>