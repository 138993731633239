<template>
  <div class="wrap">
    <distribution-circle-diagram :data="data" />
    <div class="info-container">
      <ul>
        <li>
          <span class="widget_color economy_color"></span>
          {{ T("Web.DashboardPage.WidgetStore.Widgetss.ConcernWasteCategoriesDistributionWidget.Economy", "Financials") }}
        </li>
        <li>
          <span class="widget_color tons_color"></span>
          {{ T("Web.Generic.Units.Tonnage") }}
        </li>
        <li>
          <span class="widget_color co2_color"></span>
          {{ T("Web.Generic.Units.CO2", "Co2") }}
        </li>
        <br />
        <li>
          <svg width="24" height="24">
            <path
              d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
              fill="gray"
            />
          </svg>
          {{ T("Web.Generic.WasteCategories.Reuse") }}
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M13.9695 10.7258H16.9999V7.73099"
              stroke="gray"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
              stroke="gray"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.0305 13.2551H7V16.2499"
              stroke="gray"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
              stroke="gray"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ T("Web.Generic.WasteCategories.Rezycl") }}
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
              fill="gray"
            />
          </svg>
          {{ T("Web.Generic.WasteCategories.Combostion") }}
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M10 11H12V18"
              stroke="gray"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
              fill="gray"
            />
          </svg>
          {{ T("Web.Generic.WasteCategories.Special") }}
        </li>
        <li>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
              fill="gray"
            />
          </svg>
          {{ T("Web.Generic.WasteCategories.Landfill") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DistributionCircleDiagram from "./DistributionCircleDiagram.vue";
export default {
  components: { DistributionCircleDiagram },
  props: {
    data: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ul > li {
      display: flex;
      text-decoration: none;
      list-style: none;
      margin-bottom: 5px;
      align-items: center;

      .widget_color {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        display: block;
        margin-right: 5px;
      }
      .economy_color {
        background-color: #438651;
      }
      .tons_color {
        background-color: #305e38;
      }
      .co2_color {
        background-color: #234328;
      }
    }
  }
}
</style>