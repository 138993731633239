<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <div class="inner">
      <div class="header d-flex justify-content-between">
        <div>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
        </div>
        <div>
          <feather-icon
            size="20"
            icon="MinimizeIcon"
            v-if="filterSettingsVisible == true && !editMode"
            @click="filterSettingsVisible = false"
          />
          <feather-icon
            size="20"
            icon="FilterIcon"
            @click="filterSettingsVisible = true"
            v-else-if="!editMode"
          />
        </div>
      </div>
      <div class="body">
        <div class="filter-settings mt-1" v-if="filterSettingsVisible">
          <div class="d-flex justify-content-between mb-1">
            <treeselect
              class="mr-1"
              :options="locationsOptions"
              :clearable="true"
              :multiple="true"
              :limit="0"
              :limitText=" () => `${T(
                'Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.ShowingFor',
                'Showing for'
              )} ${numberOfSelectedLocations} ${T(
                'Web.Generic.Locations.Location'
              )}`"
              :placeholder="T(
                'Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.ShowingForAllLocations',
                'Showing for all locations'
              )"
              v-model="item.settings.locations"
              @input="updateWidgetSettings"
              :disabled="editMode"
            />
            <treeselect
              :options="fractionsOptions"
              :clearable="false"
              :multiple="false"
              :placeholder="T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.ChooseAFractionToContinue', 'Choose a fraction to continue')"
              v-model="item.settings.fractionId"
              @input="updateWidgetSettings"
              :disabled="editMode"
            />
          </div>
          <div>
            <periode-picker
              v-model="item.settings.periodeScope"
              :disabled="editMode"
              :excludeForeverOption="true"
            />
          </div>
        </div>
        <div class="total-table">
          <div>
            <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.TotalTonnage', 'Total tons')}}</span>
            <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.TotalCollections', 'Total collections')}}</span>
            <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.AvgTonsCollections', 'Avg t/c')}}</span>
          </div>
          <div>
            <span>{{ totalTonsCollected | toCurrency }}</span>
            <span>{{ collections.length | toCurrency }}</span>
            <span>{{
              collections.length > 0
                ? totalTonsCollected / collections.length
                : 0 | toCurrency
            }}</span>
          </div>
        </div>
        <div class="collections-table">
          <div class="header">
            <div>
              <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.Date', 'Date')}}</span>
              <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.WasteType', 'Waste type')}}</span>
              <span>{{T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.Weight', 'Weight [Ton]')}}</span>
            </div>
          </div>
          <div class="body">
            <div class="nothing-to-show-text" v-if="collections.length == 0">
              {{ T("Web.Generic.TableEmpty", "No items to show") }}
            </div>
            <div v-for="(collection, index) in collections" :key="index">
              <span>{{ format_date(collection.postingDate) }}</span>
              <span>{{ collection.wasteFractionName }}</span>
              <span>{{ collection.tonsCollected | toCurrency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BTr,
  BTd,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import { mapGetters } from "vuex";
import ResourceService from "@/services/base/resource.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PeriodePicker from "../common/PeriodePicker.vue";
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    Treeselect,
    BaseWidget,
    BCardTitle,
    BCardSubTitle,
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    PeriodePicker,
  },
  async created() {
    const service = new ResourceService("waste-fractions");
    this.fractions = (await service.get("")).data.data;
    if (!this.item.settings.fractionId) {
      this.filterSettingsVisible = true;
    } else {
      await this.fetchCollections();
    }
  },
  data() {
    return {
      fractions: [],
      filterSettingsVisible: false,
      collections: [],
    };
  },
  computed: {
    ...mapGetters({ loactions: "locations/list" }),
    locationsOptions() {
      return this.loactions.map((item) => {
        return {
          label: item.name,
          id: item.id,
        };
      });
    },
    fractionsOptions() {
      return this.fractions.map((item) => {
        return {
          label: item.name,
          id: item.id,
        };
      });
    },
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;
      let title = this.T("Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Title");
      if (this.selectedFraction != null) {
        title += ` | ${this.selectedFraction.name}`;
      }
      return title;
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    selectedFraction() {
      if (!this.item.settings.fractionId) return null;
      return this.fractions.find((o) => o.id == this.item.settings.fractionId);
    },
    totalTonsCollected() {
      return this.collections.reduce(
        (pre, curr) => pre + curr.tonsCollected,
        0
      );
    },
    periodeScope() {
      return this.item.settings.periodeScope;
    },
    numberOfSelectedLocations()
    {
      return (this.item.settings.locations) ? this.item.settings.locations.length : 0 
    }
  },
  methods: {
    async updateWidgetSettings() {
      const service = new ResourceService(
        `concern/user-dashboards/${this.dashboardId}/items`
      );
      service.patch(this.item.id, this.item);
      await this.fetchCollections();
    },
    async fetchCollections() {
      const service = new ResourceService(
        `concern/statistics/wastefractions/${this.selectedFraction.id}/amount-collected`
      );
      let params = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationIds: this.item.settings.locations,
      };
      this.collections = (await service.get("", params)).data.data;
    },
  },
  watch: {
    periodeScope: {
      handler(newValue, oldValue) {
        if (
          newValue.periode == "CustomPeriod" &&
          (!newValue.customFromDate || !newValue.customToDate)
        )
          return;
        this.updateWidgetSettings();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > .body {
    padding: 10px 0px;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    .total-table {
      width: 75%;
      border: 1px solid #e4e4e4;
      background: #cadbc9;
      padding: 2px 5px;
      margin-bottom: 20px;
      border-radius: 4px;

      > div {
        display: flex;
        justify-content: space-between;

        &:first-child {
          font-weight: bold;
        }

        span {
          width: 100%;
          text-align: center;
        }
      }
    }
    .collections-table {
      height: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      border: 1px solid #e4e4e4;
      border-radius: 4px;

      > div {
        &:first-child {
          background: #f3f2f7;
        }
        div {
          display: flex;
          justify-content: space-between;
          width: 100%;

          span {
            width: 100%;
            text-align: center;
          }
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        border-bottom: 2px solid;
      }

      .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        span {
          &:last-child {
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
::v-deep .vue-treeselect__limit-tip-text {
  color: #000 !important;
  font-weight: normal !important;
}
.nothing-to-show-text {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100%;
}
</style>