import moment from "moment";
import translations from "@/translations";
export const TimeSpanHelper = {
    getOptions() {
        return [
            { text: translations.T('Web.Generic.TimeSpans.Forever', 'Forever'), value: null },
            { text: translations.T('Web.Generic.TimeSpans.PastYear', 'Past year'), value: "PastYear" },
            { text: translations.T('Web.Generic.TimeSpans.PastSixMonths', 'Past six months'), value: "PastSixMonths" },
            { text: translations.T('Web.Generic.TimeSpans.PastThreeMonths', 'Pasth three months'), value: "PastThreeMonths" },
            { text: translations.T('Web.Generic.TimeSpans.PastMonth', 'Past month'), value: "PastMonth" },
            { text: translations.T('Web.Generic.TimeSpans.CustomPeriod', 'Custom periode'), value: "CustomPeriod" },
        ]
    },
    getOptionNameFromPeriodeScope(periodeScope) {
        if (periodeScope.periode == "CustomPeriod") {
            return `${moment(String(periodeScope.customFromDate)).format("DD/MM/yyyy")} - ${moment(String(periodeScope.customToDate)).format("DD/MM/yyyy")}`
        }
        return this.getOptions().find(o => o.value == periodeScope.periode).text


    },
    getFromAndToDateFromPeriodeScope(periodeScope) {
        let result = null;
        switch (periodeScope.periode) {
            case "PastYear":
                result = {
                    fromDate: (new Date((new Date()).setFullYear(new Date().getFullYear() - 1))).toLocaleDateString("en-Us"),
                    toDate: (new Date()).toLocaleDateString("en-Us"),
                }
                break;
            case "PastSixMonths":
                result = {
                    fromDate: (new Date((new Date()).setMonth(new Date().getMonth() - 6))).toLocaleDateString("en-Us"),
                    toDate: (new Date()).toLocaleDateString("en-Us"),
                }
                break;
            case "PastThreeMonths":
                result = {
                    fromDate: (new Date((new Date()).setMonth(new Date().getMonth() - 3))).toLocaleDateString("en-Us"),
                    toDate: (new Date()).toLocaleDateString("en-Us"),
                }
                break;
            case "PastMonth":
                result = {
                    fromDate: (new Date((new Date()).setMonth(new Date().getMonth() - 1))).toLocaleDateString("en-Us"),
                    toDate: (new Date()).toLocaleDateString("en-Us"),
                }
                break;
            case "CustomPeriod":
                result = {
                    fromDate: periodeScope.customFromDate,
                    toDate: periodeScope.customToDate,
                }
                break;
        }
        return result;
    }
}