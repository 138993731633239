<template>
  <v-chart :autoresize="true" class="chart" :option="options" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  props: {
    data: {
      required: false,
      default: [],
    },
  },
  computed: {
    options() {
      return {
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) =>
            `${this.$options.filters.toCurrency(value)} ${this.unit}`,
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.data.map((i) => this.T(`Web.Generic.PostingCategories.${i.postingCategory}`)),
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 55,
            overflow: "truncate",
            width: "60",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} " + this.unit,
          },
        },
        series: [
          {
            data: this.data.map((i) => i.quantity),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            itemStyle: {
              //color: "#407e3d",
              //borderRadius: [5, 5, 0, 0],
            },
          },
        ],
      };
    },
    unit() {
      return this.T('Web.Generic.Units.Pcs');
    },
  },
};
</script>