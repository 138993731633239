<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="
        T(
          'Web.DashboardPage.WidgetStore.Widgets.WidgetSettings',
          'Widget settings'
        )
      "
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{
              T(
                "Web.DashboardPage.WidgetStore.Widgets.SaveSettings",
                "Save settings"
              )
            }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{
          T(
            "Web.DashboardPage.WidgetStore.Widgets.WidgetSettings",
            "Widget settings"
          )
        }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header d-flex justify-content-between">
        <div>
          <b-card-title>{{
            T(
              "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.Title",
              "The presence of mandatory fractions"
            )
          }}</b-card-title>
          <b-card-sub-title>{{ locationFilterText }}</b-card-sub-title>
        </div>
        <b-button size="sm" @click="refresh"
          ><feather-icon icon="RefreshCwIcon"
        /></b-button>
      </div>
      <div class="mt-1">
        <page-loader :loading="loading">
          <b-table-simple small responsive v-if="!loading">
            <b-thead>
              <b-th>{{ T("Web.Generic.Infocards.Fraction") }}</b-th>
              <b-th>{{ T("Web.Generic.Infocards.EWCCode") }}</b-th>
              <b-th>{{ T("Web.Generic.Description") }}</b-th>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(fraction, index) in fractions"
                :key="index"
                :class="{
                  'table-danger':
                    getLocationsMissingEakFraction(fraction.eak).length > 0,
                }"
                class="table-primary"
                v-b-tooltip.hover.top="
                  getLocationsMissingEakFraction(fraction.eak).length > 0
                    ? `${T(
                        'Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.MissingOn', 'Missing on'
                      )}: ${getCommaSeperatedLocationNames(
                        getLocationsMissingEakFraction(fraction.eak)
                      )}`
                    : T('Web.Generic.Locations.AllLocations')
                "
              >
                <b-td>{{ fraction.label }}</b-td>
                <b-td>{{ fraction.eak }}</b-td>
                <b-td
                  ><small>{{ fraction.description }}</small></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </page-loader>
      </div>
    </div>
  </base-widget>
</template>
<script>
import BaseWidget from "../common/BaseWidget.vue";
import {
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BTr,
  BTd,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BDropdownItem,
} from "bootstrap-vue";
import InfocardsService from "@/services/app/background-data/infocards.service";
import { mapGetters } from "vuex";
import { VBTooltip } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";

export default {
  components: {
    BaseWidget,
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTr,
    BTd,
    BCardTitle,
    BCardSubTitle,
    BButton,
    PageLoader,
    ContentModal,
    SettingsModalInner,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
      loading: false,
      infocardsGroupedByLocations: {},
      fractions: [
        {
          label: "Restaffald",
          eak: "20.03.99",
          description:
            "Husholdningsaffald og lignende handels-, industri- og institutionsaffald, ikke andetsteds specificeret",
        },
        {
          label: "Madaffald",
          eak: "20.02.01",
          description: "Bionedbrydeligt affald",
        },
        {
          label: "Plast",
          eak: "20.01.39",
          description: "Plast",
        },
        {
          label: "Farligt affald",
          eak: "20.01.28",
          description:
            "Maling, trykfarver, klæbestoffer og harpikser, bortset fra affald henhørende under 20 01 27",
        },
        {
          label: "Tekstiler",
          eak: "20.01.11",
          description: "Tekstiler",
        },
        {
          label: "Glas",
          eak: "20.01.02",
          description: "Glas",
        },
        {
          label: "Pap",
          eak: "20.01.01",
          description: "Papir og pap",
        },
        {
          label: "Papir",
          eak: "20.01.02",
          description: "Papir og pap",
        },
        {
          label: "Metal",
          eak: "17.04.05",
          description: "Jern og stål",
        },
        {
          label: "Mad- drikkekartoner",
          eak: "15.01.06",
          description: "Genanvendeligt emballage affald til sortering",
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    locationFilterText() {
      if (this.item.settings.locationScopeId) {
        return this.locations.find(
          (l) => l.id == this.item.settings.locationScopeId
        ).name;
      } else {
        return this.T('Web.Generic.Locations.AllLocations');
      }
    },
  },
  methods: {
    async refresh() {
      this.loading = true;
      const service = new InfocardsService();
      let params = {};
      if (this.item.settings.locationScopeId) {
        params = { locationId: this.item.settings.locationScopeId };
      }

      let result = (await service.all(params)).data;
      result = result.filter((i) => i.type == "ContainerInfoCard");
      result = result.reduce(function (prev, cur) {
        if (prev[cur.locationId] == undefined) {
          prev[cur.locationId] = [];
        }
        prev[cur.locationId].push(cur);
        return prev;
      }, {});
      this.infocardsGroupedByLocations = result;
      this.loading = false;
    },
    getLocationsMissingEakFraction(eak) {
      let result = [];
      for (const location in this.infocardsGroupedByLocations) {
        const locationInfocards = this.infocardsGroupedByLocations[location];
        if (!locationInfocards.some((i) => i.ewcCode == eak)) {
          result.push(this.locations.find((l) => l.id == location));
        }
      }
      return result;
    },
    getCommaSeperatedLocationNames(locations) {
      return locations.reduce((prev, curr) => {
        if (prev != "") prev += ", ";
        prev += curr.name;
        return prev;
      }, "");
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      //await this.fetchData();
      this.$bvModal.hide(this.item.id);
      await this.refresh();
    },
  },
};
</script>
<style lang="scss" scoped>
.inner {
  .header {
    margin-right: 10px;
  }
}
</style>