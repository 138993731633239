<template>
  <b-container fluid>
    <b-row class="wrap">
      <b-col cols="8">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.CustomTitle',
              'Custom title'
            )
          "
        >
          <b-form-input v-model="settings.customTitle" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="T('Web.DashboardPage.WidgetStore.Widgets.Periode', 'Periode')"
        >
          <periode-picker v-model="settings.periodeScope" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.LocationsFilter',
              'Location filter'
            )
          "
        >
          <locations-mulit-select
            :locations="locations"
            v-model="settings.locationsFilter"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { BContainer, BRow, BCol, BFormGroup, BFormInput } from "bootstrap-vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import LocationsMulitSelect from "../common/LocationsMulitSelect.vue";

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    LocationsMulitSelect,
    PeriodePicker,
    BFormGroup,
    BFormInput,
    BContainer,
    BRow,
    BCol,
  },

  computed: {
    ...mapGetters({ locations: "locations/list" }),
  },
};
</script>