<template>
  <div class="widget-container">
    <div class="widget-options">
      <b-dropdown
        variant="link"
        no-caret
        right
        class="chart-dropdown"
        toggle-class="p-0"
        v-if="editMode"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <slot name="options">
        </slot>
        <b-dropdown-item href="#" @click.prevent="$emit('remove-clicked')">
          <span class="text-danger">{{T('Web.DashboardPage.WidgetStore.Widgets.RemoveFromDashboard', 'Remove from dashboard')}} <feather-icon icon="TrashIcon" /> </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  props:{
    editMode:{
      required: false
    }
  },
  components: {
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: 0 0 37px rgb(8 21 66 / 5%);
  box-shadow: 0 0 37px rgb(8 21 66 / 5%);
  padding: 15px;
  overflow: hidden;

  .widget-options {
    position: absolute;
    right: 0;
    top: 0px;
    padding: 10px;
  }

  .close {
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%);
    border-radius: 0.357rem;
    background: #fff;
    margin: 0;
    opacity: 1;
    z-index: 99999;
    visibility: hidden;
  }
  &:hover {
    .close {
      visibility: visible;
    }
  }
}
</style>

<style >
.card-title {
    margin-bottom: 0.8rem;
}
</style>