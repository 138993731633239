<template>
  <div
    class="btn-container"
    @click="(e) => $emit('click', e)"
  >
    <slot />
    <span class="icon">
      <feather-icon :icon="icon" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  background-color: #fff;
  border-radius: 20px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  padding-left: 15px;
  font-weight: bold;
  color: #5e5873;
  box-shadow: 0 2px 2px 0 rgb(34 41 47 / 10%);

  .icon {
    margin-left: 20px;
    width: 35px;
    height: 35px;
    background: #000;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: rgba(64, 126, 61, 0.12);
    .icon {
      background: #407e3d;
    }
    color: #407e3d;
    cursor: pointer;
  }
}
</style>