<template>
  <page-loader :loading="loading">
    <b-container fluid>
      <b-row class="wrap">
        <b-col cols="8">
          <b-form-group
            :label="
              T(
                'Web.DashboardPage.WidgetStore.Widgets.CustomTitle',
                'Custom title'
              )
            "
          >
            <b-form-input v-model="settings.customTitle" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            :label="T('Web.DashboardPage.WidgetStore.Widgets.DataSource')"
          >
            <b-form-select
              :options="dataSourceOptions"
              v-model="settings.dataSource"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="
              T('Web.DashboardPage.WidgetStore.Widgets.Periode', 'Periode')
            "
          >
            <periode-picker v-model="settings.periodeScope" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="
              T(
                'Web.DashboardPage.WidgetStore.Widgets.LocationsFilter',
                'Location filter'
              )
            "
          >
            <locations-mulit-select
              :locations="locations"
              v-model="settings.locationsFilter"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="
              T(
                'Web.DashboardPage.WidgetStore.Widgets.FractionsFilter',
                'Fractions filter'
              )
            "
          >
            <waste-fraction-multi-select
              v-model="settings.wasteFractionsFilter"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </page-loader>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BContainer,
  BFormInput,
} from "bootstrap-vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import { mapGetters } from "vuex";
import LocationsMulitSelect from "../common/LocationsMulitSelect.vue";
import WasteFractionMultiSelect from "../common/WasteFractionMultiSelect.vue";
export default {
  components: {
    BFormInput,
    BContainer,
    BFormSelect,
    BFormGroup,
    BRow,
    BCol,
    PeriodePicker,
    PageLoader,
    LocationsMulitSelect,
    WasteFractionMultiSelect,
  },
  props: {
    settings: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    dataSourceOptions() {
      return [
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeBrutto"),
          value: "IncomeBrutto",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeNetto"),
          value: "IncomeNetto",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeBrutto"),
          value: "ChargeBrutto",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeNetto"),
          value: "ChargeNetto",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.CollectionsCount",
            "Collections (amount)"
          ),
          value: "WasteCollections",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.TonsCollected"),
          value: "TonsWasteCollected",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.CubicCollected"),
          value: "CubicWasteCollected",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.CO2Emitted"),
          value: "CO2Emission",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.CO2EmittedIncTransport"
          ),
          value: "CO2EmissionIncludingTransport",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.KmDriven",
            "Km driven"
          ),
          value: "KmDriven",
        },
        { text: "Tidsforbrug (Timer)", value: "HourlyConsumption" },
        { text: "Genanvendelsesprocent", value: "RecyclingRate"}
      ];
    },
  },
};
</script>