<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <div class="inner">
      <div class="header d-flex justify-content-between">
        <div>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
        </div>
        <div>
          <feather-icon
            size="20"
            icon="MinimizeIcon"
            v-if="filterSettingsVisible == true && !editMode"
            @click="filterSettingsVisible = false"
          />
          <feather-icon
            size="20"
            icon="FilterIcon"
            @click="filterSettingsVisible = true"
            v-else-if="!editMode"
          />
        </div>
      </div>
      <div class="body">
        <div class="filter-settings mt-1" v-if="filterSettingsVisible">
          <div class="d-flex justify-content-between mb-1">
            <treeselect
              :options="locationInfocardsOptions"
              :clearable="false"
              :multiple="false"
              :placeholder="
                T(
                  'Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.ChooseInfocardToContinue',
                  'Vælg et infokort for at fortsætte'
                )
              "
              :disabled="editMode"
              :disableBranchNodes="true"
              v-model="item.settings.infocardId"
              @input="updateWidgetSettings"
            />
          </div>
          <div>
            <periode-picker
              v-model="item.settings.periodeScope"
              :disabled="editMode"
              :excludeForeverOption="true"
            />
          </div>
        </div>
        <div class="header-tables" v-if="selectedInfocard != null">
          <div>
            <div class="sum-table">
              <div>
                <span></span>
                <span></span>
                <span>{{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.ContainerType",
                    "Container type"
                  )
                }}</span>
                <span>
                  {{
                    T(
                      "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.AvgTonsPrCollection",
                      "Average tons/collection"
                    )
                  }}
                </span>
                <span></span>

                <span>
                  {{
                    T(
                      "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.AvgPricePrTonnage",
                      "Average price/tons"
                    )
                  }}
                </span>
              </div>
              <div>
                <span></span>
                <span></span>
                <span>{{ selectedInfocardContainerText }}</span>
                <span>{{ convertTo3decimals(avgTonsPrCollection) }}</span>
                <span></span>

                <span>{{
                  ((totalChargeRevenue + totalCollectionCost) / totalTons)
                    | toCurrency
                }}</span>
              </div>
            </div>
            <div class="sum-table">
              <div>
                <span></span>
                <span></span>
                <span>
                  {{
                    T(
                      "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.TotalCollections",
                      "Total collections"
                    )
                  }}
                </span>
                <span>
                  {{
                    T(
                      "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.TotalTons",
                      "Total tons"
                    )
                  }}</span
                >
                <span></span>

                <span>
                  {{
                    T(
                      "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.AvgPricePrCollection",
                      "Collection/price"
                    )
                  }}</span
                >
              </div>
              <div>
                <span></span>
                <span></span>
                <span>{{ totalCollections | toCurrency }}</span>
                <span>{{ convertTo3decimals(totalTons) }}</span>
                <span></span>

                <span>{{ totalCollectionCost | toCurrency }}</span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.FractionTotal",
                    "Fraction total"
                  )
                }}
              </span>
              <span>{{
                (totalChargeRevenue + totalCollectionCost) | toCurrency
              }}</span>
            </div>
            <div>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.SummedChargeIncome",
                    "Charge/income sum"
                  )
                }}
              </span>
              <span>{{ totalChargeRevenue | toCurrency }}</span>
            </div>
          </div>
        </div>
        <div class="entries-table">
          <div class="header">
            <div>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.Date",
                    "Date"
                  )
                }}
              </span>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.WasteType",
                    "Waste type"
                  )
                }}
              </span>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.NumberOfCollections",
                    "Number of collections"
                  )
                }}
              </span>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.Weight",
                    "Weight [Ton]"
                  )
                }}</span
              >
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.CollectionUnitPrice",
                    "Collection unit price"
                  )
                }}
                [{{ currencyScope }}]
              </span>
              <span>
                {{
                  T(
                    "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.CollectionTotalPrice",
                    "Collection price"
                  )
                }}
                [{{ currencyScope }}]</span
              >
              <span>{{
                T(
                  "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Table.ChangeIncome",
                  "Charge/income"
                )
              }}</span>
            </div>
          </div>
          <div class="body">
            <div class="nothing-to-show-text" v-if="entries.length == 0">
              {{ T("Web.Generic.TableEmpty", "No items to show") }}
            </div>
            <div v-for="(entry, index) in entries" :key="index">
              <span>{{ format_date(entry.postingDate) }}</span>
              <span>{{ entry.wasteFraction }}</span>
              <span>{{ entry.wasteCollectionCount | toCurrency }}</span>
              <span>{{ convertTo3decimals(entry.tonsCollected) }}</span>
              <span>{{ entry.wasteCollectionUnitPrice | toCurrency }}</span>
              <span>{{ entry.wasteCollectionCost | toCurrency }}</span>
              <span
                >{{
                  (entry.wasteCollectionCharge + entry.wasteCollectionIncome)
                    | toCurrency
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-widget>
</template>
<script>
import { BCardSubTitle, BCardTitle } from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import BaseWidget from "../common/BaseWidget.vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import ResourceService from "@/services/base/resource.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseWidget,
    BCardSubTitle,
    BCardTitle,
    PeriodePicker,
    Treeselect,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  data() {
    return {
      filterSettingsVisible: false,
      locationInfocards: [],
      selectedInfocard: null,
      entries: [],
    };
  },
  async created() {
    await this.fetchSelectedInfocard();
    if (this.selectedInfocard == null) this.filterSettingsVisible = true;
    else this.fetchWidgetData();
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;
      let title = this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernInfocardCollectionsWidget.Title"
      );
      if (this.selectedInfocard != null) {
        title += ` | ${this.selectedInfocard.name} - ${this.selectedInfocardLocation.name}`;
      }
      return title;
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    locationInfocardsOptions() {
      return this.locationInfocards.map((item) => {
        return {
          label: item.name,
          id: item.id,
          children: item.infoCards.map((ic) => {
            return {
              label: `${ic.name} (${ic.refId})`,
              id: ic.id,
            };
          }),
        };
      });
    },
    periodeScope() {
      return this.item.settings.periodeScope;
    },
    selectedInfocardContainerText() {
      let text = "";
      const typeName = this.selectedInfocard.containerTypeName;
      const containerSize = this.selectedInfocard.containerSize;
      const containerSizeUnit = this.getContainerSizeUnit(
        this.selectedInfocard.containerSizeUnit
      );

      if (typeName && typeName != "") {
        text += typeName;
        if (containerSize && containerSize > 0) text += " - ";
      }

      if (containerSize && containerSize > 0) {
        text += this.$options.filters.toCurrency(containerSize);
        if (containerSizeUnit) text += containerSizeUnit.text;
      }

      return text;
    },
    totalCollections() {
      return this.entries.reduce(
        (prev, curr) => (prev += curr.wasteCollectionCount),
        0
      );
    },
    totalTons() {
      return this.entries.reduce(
        (prev, curr) => (prev += curr.tonsCollected),
        0
      );
    },
    avgTonsPrCollection() {
      if (this.totalCollections == 0) return 0;
      return this.totalTons / this.totalCollections;
    },
    totalCollectionCost() {
      return this.entries.reduce(
        (prev, curr) => (prev += curr.wasteCollectionCost),
        0
      );
    },
    totalChargeRevenue() {
      return this.entries.reduce(
        (prev, curr) =>
          (prev += curr.wasteCollectionCharge + curr.wasteCollectionIncome),
        0
      );
    },
    selectedInfocardLocation() {
      if (!this.selectedInfocard) return null;
      return this.locations.find(
        (l) => l.id == this.selectedInfocard.locationId
      );
    },
  },
  methods: {
    async fetchLocationInfocards() {
      const service = new ResourceService("locations");
      this.locationInfocards = (
        await service.get("", { include: "infoCards" })
      ).data.data;
    },
    async updateWidgetSettings() {
      const service = new ResourceService(
        `concern/user-dashboards/${this.dashboardId}/items`
      );
      service.patch(this.item.id, this.item);
      this.fetchWidgetData();
    },
    async fetchSelectedInfocard() {
      if (!this.item.settings.infocardId) return;
      const service = new ResourceService("background-data/infocards");
      this.selectedInfocard = (
        await service.get(this.item.settings.infocardId)
      ).data.data;
    },
    async fetchWidgetData() {
      this.fetchSelectedInfocard();
      const service = new ResourceService("concern/statistics/infocards");
      this.entries = (
        await service.get(`${this.item.settings.infocardId}/collections`, {
          ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
            this.item.settings.periodeScope
          ),
        })
      ).data.data;
    },
    convertTo3decimals(value) {
      let val = value;
      if (typeof value !== "number" || isNaN(value)) val = 0;

      return val.toLocaleString("da-DK", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    },
  },
  watch: {
    filterSettingsVisible(newValue) {
      if (newValue) this.fetchLocationInfocards();
    },
    periodeScope: {
      handler(newValue, oldValue) {
        if (
          newValue.periode == "CustomPeriod" &&
          (!newValue.customFromDate || !newValue.customToDate)
        )
          return;
        this.updateWidgetSettings();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nothing-to-show-text {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100%;
}
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > .body {
    padding: 10px 0px;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    .header-tables {
      display: flex;
      margin-bottom: 20px;

      > div {
        &:first-child {
          width: 87%;
          margin-right: 20px;

          .sum-table {
            border: 1px solid #e4e4e4;
            background: #cadbc9;
            padding: 2px 5px;
            border-radius: 4px;

            &:first-child {
              margin-bottom: 10px;
            }

            > div {
              display: flex;
              justify-content: space-between;

              &:first-child {
                font-weight: bold;
              }

              span {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
        &:last-child {
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          width: 13%;
          display: flex;
          flex-direction: column;

          > div {
            height: 100%;
            padding: 2px 5px;
            display: flex;
            flex-direction: column;

            &:first-child {
              margin-bottom: 12px;
            }

            > span {
              &:first-child {
                font-weight: bold;
              }
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
  .entries-table {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border: 1px solid #e4e4e4;
    border-radius: 4px;

    > div {
      &:first-child {
        background: #f3f2f7;
      }
      div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        span {
          width: 100%;
          text-align: center;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      border-bottom: 2px solid;
    }

    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      span {
        &:last-child {
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
}
</style>