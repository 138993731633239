<template>
  <b-row>
    <b-col cols="4">
      <b-form-select
        :options="options"
        v-model="content.periode"
        @change="handleInput"
      />
    </b-col>
    <b-col cols="4">
      <b-form-datepicker
        v-model="content.customFromDate"
        class="mb-2"
        :disabled="content.periode != 'CustomPeriod'"
        @input="$emit('periodchanged')"
      ></b-form-datepicker>
    </b-col>
    <b-col cols="4">
      <b-form-datepicker
        class="mb-2"
        :disabled="content.periode != 'CustomPeriod'"
        v-model="content.customToDate"
        @input="$emit('periodchanged')"
      ></b-form-datepicker>
    </b-col>
  </b-row>
</template>

<script>
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import { BRow, BCol, BFormSelect, BFormDatepicker } from "bootstrap-vue";
export default {
  props: {
    value: {
      required: true,
    },
    excludeForeverOption: {
      required: false,
      default: false,
    },
  },
  components: {
    BFormDatepicker,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    options() {
      let options = TimeSpanHelper.getOptions();

      if (this.excludeForeverOption)
        options = options.filter((o) => o.value != null);

      return options;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
      this.$emit("periodchanged");
    },
  },
};
</script>