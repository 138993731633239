<template>
  <treeselect
    class="mt-1"
    :options="options"
    :value-consists-of="'LEAF_PRIORITY'"
    :placeholder="placeholder"
    v-model="content"
    @input="handleInput()"
    :multiple="multiple"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import translations from '@/translations';
export default {
  components: { Treeselect },
  props: {
    value: {
      required: true,
    },
    multiple: {
      required: false,
      default: true,
    },
    placeholder: {
      required: false,
      default: translations.T(
        "Web.Generic.WasteFractions.ChooseFractions",
        "Choose fraction(s)"
      ),
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  mounted() {
    this.fetchWasteFractions();
  },
  computed: {
    ...mapGetters({ wasteFractions: "wasteFractions/list" }),
    options() {
      return this.wasteFractions.map((f) => {
        return {
          id: f.id,
          label: f.name,
        };
      });
    },
  },
  methods: {
    ...mapActions({ fetchWasteFractions: "wasteFractions/fetchList" }),
    handleInput(e) {
      this.$emit("input", this.content);
    },
  },
};
</script>