<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body">
        <locations-fractions-bar-chart
          v-if="!loading"
          :location-data="locationData"
          :fraction-list="fractionList"
          :unit="unit"
        />
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import ResourceService from "@/services/base/resource.service";
import LocationsFractionsBarChart from "./LocationsFractionsBarChart.vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
export default {
  components: {
    BaseWidget,
    BCardTitle,
    BCardSubTitle,
    BDropdownItem,
    BButton,
    ContentModal,
    SettingsModalInner,
    LocationsFractionsBarChart,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  async mounted() {
    await this.fetchData();
  },
  data() {
    return {
      loading: true,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
      data: null,
    };
  },
  computed: {
    dataSource() {
      return this.item.settings.dataSource;
    },
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;
      let title = this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernFractionsOverviewWidget.Title",
        "Fraction overview"
      );
      switch (this.dataSource) {
        case "TonsWasteCollected":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.TonsCollected", "Collected (Tonnage)")}`;
        case "CubicWasteCollected":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.CubicCollected", "Collected (Cubic)")}`;
        case "CO2Emission":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.CO2Emitted", "Co2 emitted")}`;
        case "CO2EmissionIncludingTransport":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.CO2EmittedIncTransport", "Co2 emitted (inc. transport)"
          )}`;
        case "ChargeBrutto":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeBrutto")}`;
        case "ChargeNetto":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeNetto")}`;
        case "IncomeBrutto":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeBrutto")}`;
        case "IncomeNetto":
          return `${title} - ${this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeNetto")}`;
      }
      return "";
    },
    locationData() {
      if (this.data == null) return [];
      return this.data.map((l) => {
        return {
          name: l.name,
          data: l.fractions.map((f) => f.amount),
        };
      });
    },
    fractionList() {
      if (this.data == null) return [];
      return this.data[0].fractions.map((f) =>
        f.name == null ? this.T("Web.Generic.WithoutCategory", "Withcout category") : f.name
      );
    },
    unit() {
      switch (this.dataSource) {
        case "TonsWasteCollected":
        case "CO2Emission":
        case "CO2EmissionIncludingTransport":
          return this.T("Web.Generic.Units.Tonnage");
        case "CubicWasteCollected":
          return this.T("Web.Generic.Units.Cubic");
        case "ChargeBrutto":
        case "ChargeNetto":
        case "IncomeNetto":
        case "IncomeBrutto":
          return this.currencyScope;
      }
      return "";
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        let requestParams = {
          ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
            this.item.settings.periodeScope
          ),
          locationsFilter: this.item.settings.locationsFilter,
          wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
        };
        const service = new ResourceService(`concern/statistics`);
        let uri = "";
        switch (this.dataSource) {
          case "TonsWasteCollected":
            uri = "waste-collections/tons-collected/locations-fractions";
            break;
          case "CubicWasteCollected":
            uri = "waste-collections/cubic-collected/locations-fractions";
            break;
          case "CO2Emission":
          case "CO2EmissionIncludingTransport":
            requestParams = {
              ...requestParams,
              includeTransport:
                this.dataSource == "CO2EmissionIncludingTransport",
            };
            uri = "co2-emissions/locations-fractions";
            break;
          case "ChargeNetto":
          case "ChargeBrutto":
            requestParams = {
              ...requestParams,
              ShowBruttoResult: this.dataSource == "ChargeBrutto",
            };
            uri = "financials/expenses/locations-fractions";
            break;
          case "IncomeBrutto":
          case "IncomeNetto":
            requestParams = {
              ...requestParams,
              ShowBruttoResult: this.dataSource == "IncomeBrutto",
            };
            uri = "financials/income/locations-fractions";
            break;
        }
        let response = await service.get(uri, requestParams);
        this.data = response.data.data;
      } finally {
        this.loading = false;
      }
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>