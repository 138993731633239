<template>
  <b-container fluid>
    <b-row class="wrap">
      <b-col cols="8">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.CustomTitle',
              'Custom title'
            )
          "
        >
          <b-form-input v-model="settings.customTitle" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="T('Web.DashboardPage.WidgetStore.Widgets.DataSource')"
        >
          <b-form-select
            :options="viewModeOptions"
            v-model="settings.viewMode"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="T('Web.DashboardPage.WidgetStore.Widgets.Periode', 'Periode')"
        >
          <periode-picker v-model="settings.periodeScope" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.LocationsFilter',
              'Location filter'
            )
          "
        >
          <locations-mulit-select
            :locations="locations"
            v-model="settings.locationsFilter"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.FractionsFilter',
              'Fractions filter'
            )
          "
        >
          <waste-fraction-multi-select
            v-model="settings.wasteFractionsFilter"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BContainer,
  BFormInput,
} from "bootstrap-vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import LocationsMulitSelect from "../common/LocationsMulitSelect.vue";
import WasteFractionMultiSelect from "../common/WasteFractionMultiSelect.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BContainer,
    BFormInput,
    PeriodePicker,
    LocationsMulitSelect,
    WasteFractionMultiSelect,
  },
  props: {
    settings: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    viewModeOptions() {
      return [
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteCategoriesDistributionWidget.PyramideViewMode", "Puramid"
          ),
          value: "Pyramid",
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.CircleDiagram", "Circle diagram"),
          value: "CircleDiagram",
        },
      ];
    },
  },
};
</script>