<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <div style="height: 100%; overflow: auto">
      <div class="widget-content-header">
        <v-select
          style="min-width: 200px"
          label="contactName"
          :options="suppliersOptions"
          placeholder="Vælg leverandør"
          :clearable="false"
          v-model="selectedSupplier"
        />
        <b-form-input
          placeholder="Indtast fakturanummer"
          v-model="selectedInvoiceNumber"
        />
        <b-form-input
          type="number"
          placeholder="Tillæg i %"
          v-model="selectedAddonPct"
        />
        <b-button size="sm" @click.prevent="fetchInvoice"
          >Hent faktura</b-button
        >
      </div>
      <div class="widget-content-body">
        <template v-if="notFound">
          <h3 class="text-danger text-center mt-1">
            Der blev ikke fundet en faktura med fakturanummeret
            {{ selectedInvoiceNumber }}
          </h3>
        </template>
        <template v-else-if="invoice != null">
          <div class="invoice-preview mt-2">
            <div class="invoice-head">
              <div class="left-side">
                <h4>Modtager</h4>
                <span>{{ invoice.customer.name }}</span
                ><br />
                <span>{{ invoice.customer.address }}</span
                ><br />
                <span
                  >{{ invoice.customer.zip }} {{ invoice.customer.city }}</span
                >
              </div>
              <div class="right-side">
                <h4>#{{ invoice.invoiceNumber }}</h4>
                <span>{{ format_date(invoice.issueDate) }}</span> <br />
                <br />
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  @click.prevent="exportInvoice"
                  >Download excel <feather-icon icon="DownloadIcon"
                /></b-button>
              </div>
            </div>
            <div class="invoice-body">
              <b-table
                class="mt-1"
                :fields="tableFields"
                :items="invoice.invoiceLines"
                small
              >
                <template #cell(date)="data">
                  {{ format_date(data.value) }}
                </template>
                <template #cell(amount)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(priceAmount)="data">
                  {{ data.value | toCurrency }}
                </template>
                <template #cell(addonAmount)="data">
                  {{
                    (data.item.priceAmount *
                      (selectedAddonPct > 0 ? selectedAddonPct / 100 : 0))
                      | toCurrency
                  }}
                </template>
                <template #cell(addonPct)="data">
                  {{ selectedAddonPct | toCurrency }}
                </template>
                <template #cell(priceTotal)="data">
                  {{
                    (data.item.priceTotal +
                      data.item.priceAmount *
                        (selectedAddonPct > 0 ? selectedAddonPct / 100 : 0))
                      | toCurrency
                  }}
                </template>
              </b-table>
            </div>
          </div>
        </template>
        <template v-else>
          <h3 class="text-muted text-center mt-1">
            Vælg en leverandør og et fakturanummer for at fortsætte
          </h3>
        </template>
      </div>
    </div>
  </base-widget>
</template>

<script>
import * as XLSX from "xlsx";
import BaseWidget from "../common/BaseWidget.vue";
import { BFormInput, BButton, BTable } from "bootstrap-vue";
import vSelect from "vue-select";
import ResourceService from "@/services/base/resource.service";
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    vSelect,
    BaseWidget,
    BFormInput,
    BTable,
    BButton,
  },
  data() {
    return {
      invoice: null,
      suppliersOptions: [],
      selectedSupplier: null,
      selectedInvoiceNumber: null,
      selectedAddonPct: null,
      notFound: false,
      tableFields: [
        {
          key: "date",
          label: "Dato",
          sortable: true,
        },
        {
          key: "text",
          label: "Beskrivelse",
          sortable: true,
        },
        {
          key: "amount",
          label: "Antal",
          sortable: true,
        },
        {
          key: "unit",
          label: "Enhed",
          sortable: true,
        },
        {
          key: "priceAmount",
          label: "Enhedspris",
          sortable: true,
        },
        {
          key: "addonPct",
          label: "Tillægsprocent",
          sortable: true,
        },
        {
          key: "addonAmount",
          label: "Tillægspris",
          sortable: true,
        },

        {
          key: "priceTotal",
          label: "Totalpris",
          sortable: true,
        },
      ],
    };
  },
  async created() {
    const supplierService = new ResourceService("supplier-contacts");
    this.suppliersOptions = (await supplierService.get("")).data.data;
  },
  methods: {
    async fetchInvoice() {
      if (this.selectedSupplier == null || this.selectedInvoiceNumber == null)
        return;
      this.notFound = false;
      const invoiceService = new ResourceService(
        "economics/invoices/voucher-number"
      );
      let result = (
        await invoiceService.get(this.selectedInvoiceNumber, {
          supplierContactId: this.selectedSupplier.id,
        })
      ).data.data;
      this.invoice = result;
      if (this.invoice == null) this.notFound = true;
    },
    exportInvoice() {
      let content = this.invoice.invoiceLines.map((item) => {
        return {
          Dato: this.format_date(item.date),
          Beskrivelse: item.text,
          Antal: this.$options.filters.toCurrency(item.amount),
          Enhed: item.unit,
          Enhedspris: this.$options.filters.toCurrency(
            item.priceAmount +
              item.priceAmount *
                (this.selectedAddonPct > 0 ? this.selectedAddonPct / 100 : 0)
          ),
          TotalPris: this.$options.filters.toCurrency(
            item.priceTotal +
              item.priceAmount *
                (this.selectedAddonPct > 0 ? this.selectedAddonPct / 100 : 0)
          ),
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(content);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, `Test.xlsx`);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;

  > div {
    width: 100%;
  }
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 2px solid #ebe9f1;
  padding-bottom: 10px;
}
.widget-content-body {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .invoice-head {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .invoice-preview {
    border: 2px solid #ebe9f1;
  }
}
</style>