<template>
  <treeselect
    class="mt-1"
    :multiple="true"
    :options="options"
    :value-consists-of="'LEAF_PRIORITY'"
    :placeholder="
      T('Web.Generic.Locations.ChooseLocations', 'Choose location(s)')
    "
    v-model="content"
    @input="handleInput()"
  />
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },
  props: {
    value: {
      required: true,
    },
    locations: {
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    options() {
      return this.locations.map((location) => {
        return {
          id: location.id,
          label: location.name,
        };
      });
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
  },
};
</script>