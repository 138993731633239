<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header d-flex justify-content-between">
        <div>
          <b-card-title>{{ title }}</b-card-title>
          <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
        </div>
        <div class="d-flex justify-content-between align-items-center w-50">
          <span style="white-space: nowrap; margin-right: 10px"
            >Simulere mod:
          </span>
          <select class="form-control mr-1">
            <option value="Combostion">
              {{ T("Web.Generic.WasteCategories.Combostion") }}
            </option>
            <option value="Landfill">
              {{ T("Web.Generic.WasteCategories.Landfill") }}
            </option>
            <option value="Special">
              {{ T("Web.Generic.WasteCategories.Special") }}
            </option>
            <option value="Rezycl">
              {{ T("Web.Generic.WasteCategories.Rezycl") }}
            </option>
            <option value="Reuse">
              {{ T("Web.Generic.WasteCategories.Reuse") }}
            </option>
          </select>
        </div>
      </div>
      <div class="body">
        <b-row class="h-100">
          <b-col cols="7">
            <b-table-lite
              class="mt-1"
              fixed
              hover
              :fields="tableFields"
              :items="tableContent"
              small
            >
              <template #cell(category)="data">
                <small>
                  <svg
                    v-if="data.value == 'WasteCategoryReuse'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#438651" />
                    <path
                      d="M16.2177 13.2099L16.8544 7.34195C16.9268 6.67428 16.3336 6.12642 15.6738 6.2516L10.2192 7.28644C6.15926 8.05669 4.69404 13.1934 7.31485 16.21C8.04194 14.5733 9.56351 12.242 11.7998 10.6437C9.8016 14.487 9.13874 16.0096 8.93893 17.4813C12.0015 19.0249 15.8083 16.9832 16.2177 13.2099Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryRezycl'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#3C7A49" />
                    <path
                      d="M13.9695 10.7258H16.9999V7.73099"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 8.60812C7.51591 8.09828 8.12838 7.69386 8.80244 7.41794C9.47651 7.14201 10.199 7 10.9286 7C11.6582 7 12.3806 7.14201 13.0547 7.41794C13.7288 7.69386 14.3412 8.09828 14.8571 8.60812L17 10.7258"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0305 13.2551H7V16.2499"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 15.3728C16.4841 15.8826 15.8716 16.2871 15.1976 16.563C14.5235 16.8389 13.801 16.9809 13.0714 16.9809C12.3418 16.9809 11.6194 16.8389 10.9453 16.563C10.2712 16.2871 9.65876 15.8826 9.14286 15.3728L7 13.2552"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryCombostion'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#305E38" />
                    <path
                      d="M10.5 10.8L8.34886 9.14263C7.58199 10.3458 7 11.7647 7 13.3C7 14.6261 7.52678 15.8978 8.46447 16.8355C9.40215 17.7732 10.6739 18.3 12 18.3C13.3261 18.3 14.5979 17.7732 15.5355 16.8355C16.4732 15.8978 17 14.6261 17 13.3C17 8.79999 12.5 5.29999 12.5 5.29999L10.5 10.8Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategorySpecial'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#234328" />
                    <path
                      d="M10 11H12V18"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else-if="data.value == 'WasteCategoryLandfill'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#16291A" />
                    <path
                      d="M17.9783 15C18.1817 15 18.2999 14.7702 18.1817 14.6047L13.6675 8.28481C13.5678 8.14522 13.3604 8.14522 13.2607 8.28481L11.4053 10.8824L12.4641 13L11.147 11.2439L11.1405 11.2531L9.66468 9.26958C9.56471 9.13522 9.36349 9.13522 9.26353 9.26958L5.29703 14.6008C5.17433 14.7657 5.29204 15 5.49761 15H17.9783Z"
                      fill="white"
                    />
                  </svg>
                  <span> {{ $t("Generic." + data.value) }}</span>
                </small>
              </template>
            </b-table-lite>
          </b-col>
          <b-col cols="5" style="height: 300px"> </b-col>
        </b-row>
      </div>
    </div>
  </base-widget>
</template>

<script>
import ContentModal from "@/app/common/modals/ContentModal.vue";
import BaseWidget from "../common/BaseWidget.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import {
  BButton,
  BDropdownItem,
  BCardTitle,
  BCardSubTitle,
  BTableLite,
  BRow,
  BCol,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
export default {
  components: {
    BaseWidget,
    ContentModal,
    SettingsModalInner,
    BTableLite,
    BButton,
    BDropdownItem,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
      tableFields: [
        {
          key: "category",
          label: this.$t("Generic.Category"),
        },
        {
          key: "share",
          label: this.$t("Generic.Share"),
        },
        {
          key: "tonsCO2",
          label: this.$t("Generic.TonsCO2"),
        },
        {
          key: "tonsCO2Simulated",
          label: this.$t("Generic.TonsCO2Simulated"),
        },
        {
          key: "saving",
          label: this.$t("Generic.PotentialSavings"),
        },
      ],
    };
  },
  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return "Simulering - Kategorier";
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    tableContent() {
      return [
        {
          category: "WasteCategoryReuse",
          _cellVariants: { category: "reuse" },
        },
        {
          category: "WasteCategoryRezycl",
          _cellVariants: { category: "rezycl" },
        },
        {
          category: "WasteCategoryCombostion",
          _cellVariants: { category: "combostion" },
        },
        {
          category: "WasteCategorySpecial",
          _cellVariants: { category: "special" },
        },
        {
          category: "WasteCategoryLandfill",
          _cellVariants: { category: "landfill" },
        },
      ];
    },
  },
  methods: {
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      //await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  .header {
    margin-right: 10px;
  }
}
</style>

<style lang="scss">
.circle-container {
  display: flex;
  width: 100%;
  height: 100%;
  > div {
    width: 30%;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.table-reuse {
  background: #438651;
  color: white;
}
.table-rezycl {
  background: #3c7a49;
  color: white;
}
.table-combostion {
  background: #305e38;
  color: white;
}
.table-special {
  background: #234328;
  color: white;
}
.table-landfill {
  background: #16291a;
  color: white;
}
</style>