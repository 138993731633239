<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <div class="inner">
      <div class="header">
        <b-card-title>Rapporteringsværktøj</b-card-title>
        <b-card-sub-title>
          Vælg periode og fraktion(er) for at generer rapport(er) for "selling
          of valuable waste"</b-card-sub-title
        >
      </div>
      <div class="body">
        <b-form-group label="Fraktion(er)">
          <treeselect
            :multiple="true"
            :options="fractionOptions"
            v-model="selectedWasteFractions"
          />
        </b-form-group>
        <b-form-group label="Periode fra og til">
          <b-form-datepicker v-model="fromDate" placeholder="YYYY-MM-DD" />
          <br />
          <b-form-datepicker v-model="toDate" />
        </b-form-group>
        <b-button class="w-100" @click="generateFiles">Hent fil(er)</b-button>
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BTable,
  BDropdownItem,
  BButton,
  BFormGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moment from "moment";
import jsPDF from "jspdf";

export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    BaseWidget,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BDropdownItem,
    BButton,
    Treeselect,
    BFormGroup,
    BFormDatepicker,
  },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      fractions: [],
      fromDate: moment(new Date()).subtract("year", 1).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedWasteFractions: [],
    };
  },
  methods: {
    async fetchData() {
      const fractionService = new ResourceService("waste-fractions");
      const { data } = await fractionService.get("");
      this.fractions = data.data;
    },
    generateFiles() {
      const service = new ResourceService(
        "concern/statistics/waste-amounts/fraction-summed"
      );
      this.selectedWasteFractions.forEach(async (element) => {
        let { data } = await service.get("", {
          fractionId: element,
          fromDate: this.fromDate,
          toDate: this.toDate,
        });
        const doc = new jsPDF();
        doc.setFont("Calibri");
        doc.setFontSize(11);

        doc.text("Selling of valuable waste (F40)", 20, 40);
        doc.text("Selling of valuable waste (after the fact)", 20, 48);
        doc.text("1. Supplier name", 20, 56);
        doc.text((data.data.supplierName != null) ? data.data.supplierName  : "--", 20, 64);
        doc.text("2. Type", 20, 72);
        doc.text((data.data.type != null) ? data.data.type : "--", 20, 80);
        doc.text("3. Weight", 20, 88);
        doc.text(
          this.$options.filters.toCurrency(data.data.weight / 1000) + " Kg",
          20,
          96
        );
        doc.text("4. Unit", 20, 104);
        doc.text("Waste", 20, 112);
        doc.text("5. Sales or scrap amount total", 20, 120);
        doc.text(
          this.$options.filters.toCurrency(data.data.salesOrScrapAmountTotal)+"DKK",
          20,
          128
        );
        doc.text("6. Division name", 20, 136);
        doc.text("KUTDK", 20, 144);
        // Save the PDF
        doc.save(
          `Selling of valuable waste: ${data.data.supplierName}, ${data.data.type} (${this.fromDate} - ${this.toDate})`
        );
      });
    },
  },
  computed: {
    fractionOptions() {
      return this.fractions.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  height: 100%;
  .body {
    overflow-y: auto;
    height: calc(100% - 59px);
    margin-top: 20px;
  }
}
</style>