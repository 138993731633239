var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-loader',{attrs:{"loading":_vm.loading}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"wrap"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.T(
              'Web.DashboardPage.WidgetStore.Widgets.CustomTitle',
              'Custom title'
            )}},[_c('b-form-input',{model:{value:(_vm.settings.customTitle),callback:function ($$v) {_vm.$set(_vm.settings, "customTitle", $$v)},expression:"settings.customTitle"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.DashboardPage.WidgetStore.Widgets.DataSource')}},[_c('b-form-select',{attrs:{"options":_vm.dataSourceOptions},model:{value:(_vm.settings.dataSource),callback:function ($$v) {_vm.$set(_vm.settings, "dataSource", $$v)},expression:"settings.dataSource"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.T('Web.DashboardPage.WidgetStore.Widgets.Periode', 'Periode')}},[_c('periode-picker',{model:{value:(_vm.settings.periodeScope),callback:function ($$v) {_vm.$set(_vm.settings, "periodeScope", $$v)},expression:"settings.periodeScope"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.T(
              'Web.DashboardPage.WidgetStore.Widgets.LocationsFilter',
              'Location filter'
            )}},[_c('locations-mulit-select',{attrs:{"locations":_vm.locations},model:{value:(_vm.settings.locationsFilter),callback:function ($$v) {_vm.$set(_vm.settings, "locationsFilter", $$v)},expression:"settings.locationsFilter"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.T(
              'Web.DashboardPage.WidgetStore.Widgets.FractionsFilter',
              'Fractions filter'
            )}},[_c('waste-fraction-multi-select',{model:{value:(_vm.settings.wasteFractionsFilter),callback:function ($$v) {_vm.$set(_vm.settings, "wasteFractionsFilter", $$v)},expression:"settings.wasteFractionsFilter"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }