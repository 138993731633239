<template>
  <b-container fluid>
    <b-row class="wrap">
      <b-col cols="8">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.CustomTitle',
              'Custom title'
            )
          "
        >
          <b-form-input v-model="settings.customTitle" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="
            T('Web.DashboardPage.WidgetStore.Widgets.DataSource', 'Data source')
          "
        >
          <b-form-select
            :options="dataSourceOptions"
            v-model="settings.dataSource"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.DataViewMode',
              'Data display'
            )
          "
        >
          <b-form-select
            :options="timespanViewModeOptions"
            v-model="settings.timespanViewMode"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.VisualViewMode',
              'Visual display'
            )
          "
        >
          <b-form-select
            :options="VisualViewModeOptions"
            v-model="settings.visualViewMode"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="T('Web.DashboardPage.WidgetStore.Widgets.Periode', 'Periode')"
        >
          <periode-picker v-model="settings.periodeScope" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.LocationsFilter',
              'Location filter'
            )
          "
        >
          <locations-mulit-select
            :locations="locations"
            v-model="settings.locationsFilter"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="
            T(
              'Web.DashboardPage.WidgetStore.Widgets.FractionsFilter',
              'Fractions filter'
            )
          "
        >
          <waste-fraction-multi-select
            v-model="settings.wasteFractionsFilter"
            :multiple="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import PeriodePicker from "../common/PeriodePicker.vue";
import LocationsMulitSelect from "../common/LocationsMulitSelect.vue";
import WasteFractionMultiSelect from "../common/WasteFractionMultiSelect.vue";

export default {
  props: {
    settings: {
      required: true,
    },
  },
  components: {
    LocationsMulitSelect,
    PeriodePicker,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    WasteFractionMultiSelect,
  },

  computed: {
    ...mapGetters({ locations: "locations/list" }),
    dataSourceOptions() {
      return [
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.IncomeBrutto",
            "Gross income"
          ),
          value: "IncomeBrutto",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.IncomeNetto",
            "Net income"
          ),
          value: "IncomeNetto",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.ChargeBrutto",
            "Gross charge"
          ),
          value: "ChargeBrutto",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernInvoicedFractionPricesWidget.ChargeNetto",
            "Net charge"
          ),
          value: "ChargeNetto",
        },
      ];
    },
    timespanViewModeOptions() {
      return [
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.Monthly",
            "Monthly"
          ),
          value: "Monthly",
        },
        {
          text: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.Yearly",
            "Yearly"
          ),
          value: "Yearly",
        },
      ];
    },
    VisualViewModeOptions() {
      return [
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.Graph", "Graph"),
          value: null,
        },
        {
          text: this.T("Web.DashboardPage.WidgetStore.Widgets.Pillar"),
          value: "Pillar",
        },
      ];
    },
  },
};
</script>