<template>
  <v-chart :autoresize="true" class="chart" :option="options" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
import VChart from "vue-echarts";
export default {
  props: {
    locationData: {
      required: true,
    },
    fractionList: {
      required: true,
    },
    unit: {
      required: false,
      default: "",
    },
  },
  components: {
    VChart,
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          valueFormatter: (value) =>
            `${this.$options.filters.toCurrency(value)} ${this.unit}`,
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter: "{value} " + this.unit,
          },
        },
        xAxis: {
          type: "category",
          data: this.fractionList,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 55,
            overflow: "truncate",
            width: "60",
          },
        },
        series: this.locationData.map((d) => {
          return {
            name: d.name,
            type: "bar",
            data: d.data,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              //  borderRadius: [5, 5, 0, 0],
            },
            label: {},
          };
        }),
      },
    };
  },
};
</script>