<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body" v-if="data != null">
        <financial-overview-pillar-graph
          v-if="item.settings.visualViewMode == 'Pillar'"
          :data="dataItems"
        />
        <b-table
          v-else
          small
          responsive
          :fields="tableFields"
          :items="dataItems"
        >
          <template #cell(category)="data">
            <span class="font-weight-bold">{{ data.value }}</span>
          </template>
          <template #cell(current)="data">
            {{ data.value | toCurrency }}
          </template>
          <template #cell(lastYear)="data">
            {{ data.value | toCurrency }}
          </template>
        </b-table>
      </div>
    </div>
  </base-widget>
</template>

<script>
import ContentModal from "@/app/common/modals/ContentModal.vue";
import BaseWidget from "../common/BaseWidget.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import {
  BButton,
  BDropdownItem,
  BCardTitle,
  BCardSubTitle,
  BTable,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import FinancialOverviewPillarGraph from "./FinancialOverviewPillarGraph.vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BaseWidget,
    ContentModal,
    SettingsModalInner,
    BButton,
    BDropdownItem,
    BCardTitle,
    BCardSubTitle,
    BTable,
    FinancialOverviewPillarGraph,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  data() {
    return {
      data: null,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
    };
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.Title",
        "Financial overview"
      );
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    tableFields() {
      return [
        {
          key: "category",
          label: "",
          sortable: false,
        },
        {
          key: "current",
          label: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.BaseLine",
            "Baseline"
          ),
          sortable: false,
        },
        {
          key: "lastYear",
          label: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.TheYearBefore",
            "Same period the year before"
          ),
          sortable: false,
        },
      ];
    },
    dataItems() {
      return [
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.Logistics",
            "Logistics (rent + collections)"
          ),
          current: this.data.currentPeriode.logisticsCosts,
          lastYear: this.data.lastYearPeriode.logisticsCosts,
        },
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.Charges",
            "Charges"
          ),
          current: this.data.currentPeriode.chargeCosts,
          lastYear: this.data.lastYearPeriode.chargeCosts,
        },
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.IcomeReuse",
            "Income reuse"
          ),
          current: this.data.currentPeriode.income,
          lastYear: this.data.lastYearPeriode.income,
        },
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.OtherExpenses",
            "Other expenses"
          ),
          current: this.data.currentPeriode.otherExpenses,
          lastYear: this.data.lastYearPeriode.otherExpenses,
        },
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.WasteTotal",
            "Waste total"
          ),
          current: this.data.currentPeriode.expenseNetto,
          lastYear: this.data.lastYearPeriode.expenseNetto,
        },
        {
          category: this.T(
            "Web.DashboardPage.WidgetStore.Widgets.ConcernFinancialOverviewWidget.PricePrTons",
            "Price pr. tonnage"
          ),
          current: this.data.currentPeriode.pricePrTons,
          lastYear: this.data.lastYearPeriode.pricePrTons,
        },
      ];
    },
  },
  methods: {
    async fetchData() {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
      };
      const service = new ResourceService(`concern/statistics/financials`);
      var result = (await service.get("", requestParams)).data;
      this.data = result.data;
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>