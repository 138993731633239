<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div v-if="data != null" class="body">
        <circle-diagram-view
          :data="data"
          v-if="item.settings.viewMode == 'CircleDiagram'"
        />
        <pyramid-view :data="data" v-else />
      </div>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import PyramidView from "./PyramidView.vue";
import ResourceService from "@/services/base/resource.service";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import CircleDiagramView from "./CircleDiagramView.vue";
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    BaseWidget,
    BButton,
    BCardTitle,
    BCardSubTitle,
    BDropdownItem,
    PyramidView,
    ContentModal,
    SettingsModalInner,
    CircleDiagramView,
  },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      data: null,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
    };
  },
  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return this.T(
        "Web.DashboardPage.WidgetStore.Widgetss.ConcernWasteCategoriesDistributionWidget.Title", "Distribution overview"
      );
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
  },
  methods: {
    async fetchData() {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
      };
      const service = new ResourceService(
        `concern/statistics/waste-categories/distribution`
      );
      var result = (await service.get("", requestParams)).data;
      this.data = result.data;
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  .body {
    margin-top: 20px;
    background: #f9f9f9;
    border-radius: 6px;
    height: 100%;
  }
}
</style>