<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
      </div>
      <div class="body">
        <treeselect
          :options="infocardsOptions"
          :clearable="false"
          :disable-branch-nodes="true"
          v-model="item.settings.infocardId"
          @input="updateWidgetSettings"
          :placeholder="T('Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.ChooseInfocardToContinue', 'Select an info card to continue')"
          :disabled="editMode"
        />
        <div class="data-table" v-if="unitPricesData != null">
          <div class="header">
            <div>
              <span>{{ T("Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.Info", "Info") }}</span>
              <span></span>
              <span class="text-right">{{T("Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.UnitPrices", "Unit prices") }}</span>
              <span class="text-right">{{ T("Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.Latest", "Latests") }}</span>
            </div>
          </div>
          <div class="body">
            <div>
              <span class="font-weight-bold">{{ T("Web.DashboardPage.WidgetStore.Widgets.ConcernFractionSetsWidget.Table.InfocardName", "Infocard name") }} </span>
              <span
                >{{ currentInfocard.name }} ({{ currentInfocard.refId }})</span
              >
              <span class="table-primary font-weight-bold">{{T('Web.Generic.PostingCategories.Rent', 'Rent')}}</span>
              <span class="table-primary">{{
                unitPricesData.rentPrice | toCurrency
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{T('Web.Generic.Infocards.Quantity', 'Quantity')}} </span>
              <span>{{ currentInfocard.quantity | toCurrency }}</span>
              <span class="table-primary font-weight-bold">{{T('Web.Generic.PostingCategories.WasteCollection')}}</span>
              <span class="table-primary">{{
                unitPricesData.emptyingPrice | toCurrency
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{T('Web.Generic.Infocards.WasteFraction')}} </span>
              <span>{{ currentInfocard.wasteFractionName }}</span>
              <span class="table-primary font-weight-bold">{{T('Web.Generic.PostingCategories.Charge')}}</span>
              <span class="table-primary">{{
                unitPricesData.chargePrice | toCurrency
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{T('Web.Generic.Infocards.EWCCode')}}  </span>
              <span
                >{{ currentInfocard.ewcCode }} <br />
                <small>{{ currentInfocard.ewcName }}</small></span
              >
              <span class="table-primary font-weight-bold">{{T('Web.Generic.PostingCategories.Income')}}</span>
              <span class="table-primary">{{
                unitPricesData.incomePrice | toCurrency
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{T('Web.Generic.Infocards.Collector')}} </span>
              <span>{{ currentInfocard.collectorName }}</span>
              <span class="table-primary font-weight-bold"></span>
              <span class="table-primary"></span>
            </div>
            <div>
              <span class="font-weight-bold">{{T('Web.Generic.Infocards.RecivingFacility', 'Reciving facility')}} </span>
              <span>{{ currentInfocard.recevingFacilityName }}</span>
              <span class="table-primary font-weight-bold"></span>
              <span class="table-primary"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-widget>
</template>
<script>
import BaseWidget from "../common/BaseWidget.vue";
import { BCardTitle } from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    BaseWidget,
    BCardTitle,
    Treeselect,
  },
  async created() {
    await this.fetchInfocards();
    if (this.item.settings.infocardId) {
      await this.fetchUnitPrices();
    }
  },
  data() {
    return {
      infocards: [],
      currentInfocard: null,
      unitPricesData: null,
    };
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    infocardsOptions() {
      const self = this;
      let list = this.infocards
        .filter((i) => i.type == "ContainerInfoCard")
        .reduce(function (rv, x) {
          let location = self.locations.find((l) => l.id == x["locationId"]);
          (rv[location.name] = rv[location.name] || {
            label: location.name,
            children: [],
            id: location.id,
          }).children.push({
            id: x.id,
            name: x.name,
            label: `${x.refId} | ${x.name}`,
          });
          return rv;
        }, {});
      return Object.keys(list).map(function (key) {
        return list[key];
      });
    },
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;
      return this.T("Web.DashboardPage.WidgetStore.Widgets.ConcernUnitPricesWidget.Title");
    },
    selectedInfocard() {
      return this.infocards.find((i) => i.id == this.item.settings.infocardId);
    },
  },
  methods: {
    async fetchInfocards() {
      const service = new ResourceService("background-data/infocards");
      this.infocards = (await service.get("")).data.data;
    },
    async updateWidgetSettings() {
      const service = new ResourceService(
        `concern/user-dashboards/${this.dashboardId}/items`
      );
      service.patch(this.item.id, this.item);
      await this.fetchUnitPrices();
    },
    async fetchUnitPrices() {
      const infocardService = new ResourceService("background-data/infocards");
      this.currentInfocard = (
        await infocardService.get(this.item.settings.infocardId)
      ).data.data;
      const service = new ResourceService(
        `concern/statistics/infocards/${this.selectedInfocard.id}`
      );
      this.unitPricesData = (await service.get("latest-unitprices")).data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner,
.body {
  height: 100%;
}
.body {
  overflow-y: auto;
}

.data-table {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-top: 20px;

  > div {
    &:first-child {
      background: #f3f2f7;
    }
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        width: 100%;
        text-align: left;
        padding: 0px 10px;
        &:nth-child(3),
        &:last-child {
          width: 70%;
        }
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    border-bottom: 2px solid;
  }
  .body {
    > div {
      span {
        &:nth-child(3) {
          border-left: 2px solid;
        }
        &:last-child {
          text-align: right !important;
        }
      }
    }
  }
}
</style>