var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-widget',{attrs:{"edit-mode":_vm.editMode},on:{"remove-clicked":function($event){return _vm.$emit('remove-clicked')}},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$bvModal.show(_vm.item.id)}}},[_vm._v(" "+_vm._s(_vm.T( "Web.DashboardPage.WidgetStore.Widgets.WidgetSettings", "Widget settings" ))+" "),_c('feather-icon',{attrs:{"icon":"SettingsIcon"}})],1)]},proxy:true}])},[_c('content-modal',{attrs:{"id":_vm.item.id,"title":_vm.T(
        'Web.DashboardPage.WidgetStore.Widgets.WidgetSettings',
        'Widget settings'
      ),"body-class":"p-0"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary"},on:{"click":_vm.updateWidgetSettings}},[_vm._v(" "+_vm._s(_vm.T( "Web.DashboardPage.WidgetStore.Widgets.SaveSettings", "Save settings" ))+" ")])],1)]},proxy:true}])},[_c('settings-modal-inner',{attrs:{"settings":_vm.localSettings}})],1),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"header d-flex justify-content-between"},[_c('div',[_c('b-card-title',[_vm._v(_vm._s(_vm.T( "Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.Title", "The presence of mandatory fractions" )))]),_c('b-card-sub-title',[_vm._v(_vm._s(_vm.locationFilterText))])],1),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon"}})],1)],1),_c('div',{staticClass:"mt-1"},[_c('page-loader',{attrs:{"loading":_vm.loading}},[(!_vm.loading)?_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-th',[_vm._v(_vm._s(_vm.T("Web.Generic.Infocards.Fraction")))]),_c('b-th',[_vm._v(_vm._s(_vm.T("Web.Generic.Infocards.EWCCode")))]),_c('b-th',[_vm._v(_vm._s(_vm.T("Web.Generic.Description")))])],1),_c('b-tbody',_vm._l((_vm.fractions),function(fraction,index){return _c('b-tr',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                _vm.getLocationsMissingEakFraction(fraction.eak).length > 0
                  ? ((_vm.T(
                      'Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.MissingOn', 'Missing on'
                    )) + ": " + (_vm.getCommaSeperatedLocationNames(
                      _vm.getLocationsMissingEakFraction(fraction.eak)
                    )))
                  : _vm.T('Web.Generic.Locations.AllLocations')
              ),expression:"\n                getLocationsMissingEakFraction(fraction.eak).length > 0\n                  ? `${T(\n                      'Web.DashboardPage.WidgetStore.Widgets.ConcernWasteFractionSortingComplianceWidget.MissingOn', 'Missing on'\n                    )}: ${getCommaSeperatedLocationNames(\n                      getLocationsMissingEakFraction(fraction.eak)\n                    )}`\n                  : T('Web.Generic.Locations.AllLocations')\n              ",modifiers:{"hover":true,"top":true}}],key:index,staticClass:"table-primary",class:{
                'table-danger':
                  _vm.getLocationsMissingEakFraction(fraction.eak).length > 0,
              }},[_c('b-td',[_vm._v(_vm._s(fraction.label))]),_c('b-td',[_vm._v(_vm._s(fraction.eak))]),_c('b-td',[_c('small',[_vm._v(_vm._s(fraction.description))])])],1)}),1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }