<template>
  <v-chart :autoresize="true" class="chart" :option="options" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    data: {
      required: true,
    },
    timespanView: {
      required: false,
      default: "Monthly",
    },
    visualView: {
      required: false,
      default: null,
    },
  },
  components: {
    VChart,
  },
  computed: {
    options() {
      return {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) =>
            `${this.$options.filters.toCurrency(value)} ${this.unit}`,
        },
        legend: {
          data: !this.data
            ? []
            : this.data.locationRecords.map((item) => item.name),
        },
        grid: {
          left: "20",
          right: "30",
          bottom: "10",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chartHeader,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],

          axisLabel: {
            formatter: "{value} " + this.unit,
          },
        },
        series: this.chartData,
      };
    },
    chartLegend() {
      if (this.data == null) return [];
      return this.data.locationRecords.map((item) => item.name);
    },
    chartHeader() {
      if (!this.data) return [];
      if (this.timespanView == "Monthly")
        return this.data.locationRecords[0].wasteFractionEntries[0].statEntries.map(
          (item) => {
            const date = new Date(item.year, item.month, 1);
            return `${date.toLocaleString("default", { month: "short" })} - ${
              item.year
            }`;
          }
        );
      else
        return this.data.locationRecords[0].wasteFractionEntries[0].statEntries
          .map((item) => `${item.year}`)
          .filter((value, index, self) => self.indexOf(value) === index);
    },
    chartData() {
      if (!this.data) return [];
      return this.data.locationRecords.map((item) => {
        return {
          name: item.name,
          type: this.visualView == null ? "line" : "bar",
          data: this.getStatEntries(item.wasteFractionEntries[0]),
          itemStyle: {
            //borderRadius: [5, 5, 0, 0],
          },
        };
      });
    },
    unit() {
      return this.currencyScope;
    },
  },
  methods: {
    getStatEntries(fractionEntry) {
      if (this.timespanView == "Monthly") {
        return fractionEntry.statEntries.map((item) => item.amount);
      } else {
        return Object.values(
          fractionEntry.statEntries.reduce(function (rv, x) {
            let existingValue = rv[x["year"]] || 0;
            rv[x["year"]] = existingValue += x.amount;
            return rv;
          }, {})
        );
      }
    },
  },
};
</script>
